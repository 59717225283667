import React, { FC } from "react";
import styled from "styled-components";
import { ItemTitleProps } from "../common/types";
import { Heading } from "../../heading/Heading";
import { InternalLink } from "../../link/InternalLink";

const StyledItemTitle = styled.div`
  padding-bottom: 5px;
`;

export const ItemTitle: FC<ItemTitleProps> = ({
  productTitle,
  productUrlInShop,
  small,
}) => {
  const ItemHeading: FC = () => (
    <Heading level="h2" size={small ? "xs" : "s"}>
      {productTitle}
    </Heading>
  );
  return (
    <StyledItemTitle>
      {productUrlInShop ? (
        <InternalLink href={productUrlInShop}>
          <ItemHeading />
        </InternalLink>
      ) : (
        <ItemHeading />
      )}
    </StyledItemTitle>
  );
};
