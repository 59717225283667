import React, {
  createRef,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "react-simple-snackbar";
import cx from "classnames";
import st from "./index.module.scss";
import { useOnScreen } from "@pepdirect/helpers/useOnScreen";
import { Button } from "@pepdirect/ui/button";
import {
  SourceType,
  useEmailNewsletterSignupMutation,
} from "services/graphql/generated";
import {
  logNewsletterFormCompleteEvent,
  logNewsletterFormViewEvent,
  logNewsletterFormStartEvent,
  logNewsletterFormFailEvent,
  logNewsletterFormAbandonmentEvent,
} from "@pepdirect/helpers/analyticsLogger";

const isValidEmail = (email: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

const successOptions = {
  style: {
    backgroundColor: "#86C24F",
    color: "white",
    fontFamily: "AktivGrotesk Ex Black Italic",
    fontSize: "16px",
    textAlign: "center",
  },
  closeStyle: {
    backgroundColor: "#86C24F",
    color: "white",
    fontSize: "16px",
  },
};

const errorOptions = {
  style: {
    backgroundColor: "#AD343E",
    color: "white",
    fontFamily: "AktivGrotesk Ex Black Italic",
    fontSize: "16px",
    textAlign: "center",
  },
  closeStyle: {
    backgroundColor: "#AD343E",
    color: "white",
    fontSize: "16px",
  },
};

type NewsletterFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  emailOptIn: boolean;
};

const NewsletterForm: FC<{
  whiteBg?: boolean;
  isModal?: boolean;
  afterSuccessfulSubmit?: () => void;
  source: SourceType;
}> = ({ whiteBg, isModal, afterSuccessfulSubmit, source }) => {
  const isDirtyRef = useRef<boolean>();
  const ref = createRef<HTMLDivElement>();
  const [formViewEventFired, setFormViewEventFired] = useState(false);
  const [openSuccessSnackbar] = useSnackbar(successOptions);
  const [openErrorSnackbar] = useSnackbar(errorOptions);
  const [newsletterSignUp] = useEmailNewsletterSignupMutation();
  const isVisible = useOnScreen(ref);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm<NewsletterFormValues>({
    defaultValues: {
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      emailOptIn: undefined,
    },
  });

  const formId = useMemo(() => {
    return isModal ? "newsletter-form-modal" : "newsletter-form-footer";
  }, [isModal]);

  useEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty]);

  useEffect(() => {
    const pathname = window.location.pathname;
    return () => {
      if (isDirtyRef.current) {
        logNewsletterFormAbandonmentEvent(formId, pathname);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVisible && !formViewEventFired) {
      setFormViewEventFired(true);
      logNewsletterFormViewEvent(formId, window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const getErrorMessage = (error: string) => {
    switch (error) {
      case "unverified_email":
        return "The email entered is not verified!";
      default:
        return "An error occured while signing up newsletter!";
    }
  };

  const onSubmit = (data: NewsletterFormValues) => {
    newsletterSignUp({
      variables: {
        input: {
          email: data.email || "",
          firstName: data.firstName,
          lastName: data.lastName,
          source,
        },
      },
    })
      .then(() => {
        openSuccessSnackbar(
          "Thanks for signing up! We’ll keep you posted.",
          10000
        );
        logNewsletterFormCompleteEvent(formId, isModal ? "modal" : "footer");
        // resetting the form values after successful submit.
        reset();
        afterSuccessfulSubmit?.();
      })
      .catch((e) => {
        const message = getErrorMessage(e?.message || "");
        logNewsletterFormFailEvent(formId, message);
        openErrorSnackbar(message, 10000);
      });
  };

  const handleEmailValidation = (email: string) => {
    const isValid = isValidEmail(email);

    return isValid;
  };

  const handleOptInValidation = (optIn: boolean) => {
    return optIn;
  };

  const onFormModified = () => {
    if (!isDirty) {
      logNewsletterFormStartEvent(formId, window.location.pathname);
    }
  };

  return (
    <div ref={ref}>
      <form onSubmit={handleSubmit(onSubmit)} onChange={onFormModified}>
        <h2 className={st.heading}>JOIN THE SQUAD</h2>
        <p className={cx(st.signUpDescription, whiteBg && st.whiteBgText)}>
          Sign up for product news, exclusive deals, and more.
        </p>
        <div className={st.mainFormWrapper}>
          <div className={st.mainFormTop}>
            <div style={{ width: "50%" }}>
              <input
                {...register("firstName", { required: true })}
                style={
                  errors?.firstName
                    ? { borderColor: "#AD343E", color: "#AD343E" }
                    : {}
                }
                className={cx(st.input, whiteBg && st.whiteBgInput)}
                placeholder="First name"
              ></input>
              {errors?.firstName && (
                <span className={st.errorText}>THIS FIELD IS REQUIRED</span>
              )}
            </div>
            <div style={{ width: "50%" }}>
              <input
                {...register("lastName", { required: true })}
                style={
                  errors?.lastName
                    ? { borderColor: "#AD343E", color: "#AD343E" }
                    : {}
                }
                className={cx(st.input, whiteBg && st.whiteBgInput)}
                placeholder="Last name"
              ></input>
              {errors?.lastName && (
                <span className={st.errorText}>THIS FIELD IS REQUIRED</span>
              )}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <input
              {...register("email", {
                required: true,
                validate: handleEmailValidation,
              })}
              style={
                errors?.email
                  ? { borderColor: "#AD343E", color: "#AD343E" }
                  : {}
              }
              placeholder="Email address"
              className={cx(st.input, whiteBg && st.whiteBgInput)}
            ></input>
            {errors?.email?.type === "required" && (
              <span className={st.errorText}>THIS FIELD IS REQUIRED</span>
            )}
            {errors?.email?.type === "validate" && (
              <span className={st.errorText}>A VALID EMAIL IS REQUIRED</span>
            )}
          </div>
        </div>
        <div className={st.emailOptInWrapper}>
          <input
            {...register("emailOptIn", { validate: handleOptInValidation })}
            checked={watch("emailOptIn") || false}
            className={cx(st.checkbox, errors?.emailOptIn && st.checkboxError)}
            id={`email-opt-in-${whiteBg}`}
            type="checkbox"
          />
          <label
            className={cx(st.checkboxLabel, whiteBg && st.whiteBgText)}
            htmlFor={`email-opt-in-${whiteBg}`}
          >
            <span>
              Yes, I’d like to opt-in to receive additional product information,
              news and special offers from Rockstar Energy, PepsiCo Tasty
              Rewards and PepsiCo brands. Please see our Privacy Policy Terms of
              Use and About our Ads for details – you may opt out at any time.
            </span>
          </label>
        </div>
        {errors?.emailOptIn?.type === "validate" && (
          <span
            style={{ display: "block", marginTop: "5px", color: "#AD343E" }}
          >
            CHECKBOX SHOULD BE CHECKED
          </span>
        )}
        <Button className={st.signUpButton} htmlType="submit" fullWidth>
          SIGN UP
        </Button>
      </form>
    </div>
  );
};

export default NewsletterForm;
