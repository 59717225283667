import React, { FC } from "react";
import Modal from "react-modal";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import st from "./index.module.scss";
import NewsletterForm from "components/NewsletterForm";
import { SourceType } from "services/graphql/generated";

const NewsletterModal: FC<{ isOpen: boolean; close: () => void }> = ({
  isOpen,
  close,
}) => {
  return (
    <Modal
      onAfterClose={clearAllBodyScrollLocks}
      // Still need to check ref because the ref returns null while modal is closing.
      overlayRef={(ref) =>
        ref && disableBodyScroll(ref, { reserveScrollBarGap: true })
      }
      isOpen={isOpen}
      onRequestClose={close}
      className={st.modal}
      overlayClassName={st.overlay}
      closeTimeoutMS={250}
      ariaHideApp={false}
    >
      <button className={st.close} onClick={close} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
      <NewsletterForm
        whiteBg={true}
        afterSuccessfulSubmit={() => close()}
        isModal={true}
        source={SourceType.Default}
      />
    </Modal>
  );
};

export default NewsletterModal;
