import React, { FC } from "react";
import styled from "styled-components";
import { Discount } from "@pepdirect/shared/types";
import * as Icons from "../../icons";
import { convertFromCentsToDollars } from "../../../helpers/currency";

const { SubscriptionInfinitySvg } = Icons;

const Subscribe = styled.div`
  display: flex;
  justify-content: flex-start;
  line-height: normal;
  margin-left: -4px;
`;

const SubscribeLogo = styled.div`
  height: 20px;
  width: 20px;
`;
const SubscribeText = styled.div`
  padding-top: 2px;
  font-size: 14px;
  margin-left: 3px;
`;

const Interval = styled.div`
  font-size: 14px;
`;

interface ItemSubDetailsProps {
  discount?: Discount | null;
  interval: number | null;
  subscribed?: boolean;
}

export const ItemSubDetails: FC<ItemSubDetailsProps> = ({
  discount,
  interval,
  subscribed,
}) => {
  if (subscribed && interval) {
    const { amount, isPercent } = discount || {};
    const discountLabel =
      (amount &&
        (isPercent
          ? `& save ${amount}%`
          : `& save ${convertFromCentsToDollars(amount)}`)) ||
      "";

    return (
      <div>
        <Subscribe>
          <SubscribeLogo>
            <SubscriptionInfinitySvg color="darkGray" />
          </SubscribeLogo>
          <SubscribeText>{`Subscribe ${discountLabel}`}</SubscribeText>
        </Subscribe>
        <Interval>Deliver every {interval} days</Interval>
      </div>
    );
  }
  return null;
};
