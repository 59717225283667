import React from "react";

export const NextIcon = ({ className }: { className: string }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="recommended-next-slide"
    className={className}
  >
    <rect x="1" y="1" width="38" height="38" rx="19" fill="white" />
    <path
      d="M20 25.6795L19.234 24.9055L23.5978 20.5417H14.3206V19.4584H23.5978L19.234 15.0946L20 14.3206L25.6795 20L20 25.6795Z"
      fill="#1A1A1A"
    />
    <rect
      x="1"
      y="1"
      width="38"
      height="38"
      rx="19"
      stroke="#243C83"
      stroke-width="2"
    />
  </svg>
);

export const PrevIcon = ({ className }: { className: string }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="recommended-prev-slide"
    transform="rotate(180)"
    className={className}
  >
    <rect x="1" y="1" width="38" height="38" rx="19" fill="white" />
    <path
      d="M20 25.6795L19.234 24.9055L23.5978 20.5417H14.3206V19.4584H23.5978L19.234 15.0946L20 14.3206L25.6795 20L20 25.6795Z"
      fill="#1A1A1A"
    />
    <rect
      x="1"
      y="1"
      width="38"
      height="38"
      rx="19"
      stroke="#243C83"
      stroke-width="2"
    />
  </svg>
);
