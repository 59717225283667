// DUE TO ISSUE WITH NX https://github.com/nrwl/nx/issues/602
// MUST MANUALLY KEEP THIS FILE IN SYNC WITH libs/helpers/src/lib/currency.ts

import numeral from "numeral";

/* According to the design, we include .00 cents */
export const CURRENCY_FORMAT_OPTIONAL_CENTS = "$0,0.00";
export const FORMAT_NO_SIGN = "0.00";

export const round = (value: number, decimals: number): number => {
  const number = Number(`${value}e${decimals}`);
  const roundedValue = Math.round(number);

  return Number(`${roundedValue}e-${decimals}`);
};

export const convertFromDollarsToCents = (dollars: number): number => {
  const cents = numeral(dollars).multiply(100).value();

  return round(cents, 0);
};

export const convertFromCentsToDollars = (
  cents: number,
  format = CURRENCY_FORMAT_OPTIONAL_CENTS
): string => {
  const dollars = numeral(cents).divide(100).value();
  const roundedDollars = round(dollars, 2);

  return numeral(roundedDollars).format(format);
};

export const formatDollars = (
  dollars: number,
  format = CURRENCY_FORMAT_OPTIONAL_CENTS
): string => {
  const roundedDollars = round(dollars, 2);

  return numeral(roundedDollars).format(format);
};

export const parseDollars = (numericString: string): number => {
  return numeral().unformat(numericString);
};

export const calculatePercentageOf = (
  numerator: number,
  denominator: number,
  format: string
): string => {
  return numeral(1 - round(numerator / denominator, 2)).format(format);
};

export const convertFromCentsToDollarsUnformatted = (cents: number) => {
  const dollars = numeral(cents).divide(100).value();
  const roundedDollars = round(dollars, 2);

  return roundedDollars;
};
