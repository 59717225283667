import React, { FC } from "react";
import styled from "styled-components";
import { colors, colorNames } from "../../styles/variables";

const StyledSvg = styled.svg`
  fill: ${({ fill, theme }) => fill || theme.color.primary || colors["gray"]};
`;

interface SubscriptionInfinitySvgProps extends React.SVGProps<SVGSVGElement> {
  color: colorNames;
}

export const SubscriptionInfinitySvg: FC<SubscriptionInfinitySvgProps> = ({
  color,
  className,
}) => {
  const fill = colors[color];

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.42 10"
      fill={fill}
      className={className}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.543 16.263">
        <path
          id="Union_1"
          data-name="Union 1"
          d="M10120.907-4163.879h-3.827a5.085,5.085,0,0,1-5.08-5.081,5.085,5.085,0,0,1,5.08-5.08v2a3.082,3.082,0,0,0-3.079,3.079,3.082,3.082,0,0,0,3.079,3.08h3.827l-.43-1.963,4.5,2.959-4.5,2.959Zm5.556-2a3.082,3.082,0,0,0,3.079-3.08,3.082,3.082,0,0,0-3.079-3.079h-3.816l.43,1.958-4.5-2.959,4.5-2.96-.43,1.96h3.816a5.085,5.085,0,0,1,5.08,5.08,5.085,5.085,0,0,1-5.08,5.081Z"
          transform="translate(-10111.5 4177.094)"
          fill={fill}
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </svg>
    </StyledSvg>
  );
};
