/* eslint-disable @next/next/no-sync-scripts */
import React, { useEffect, useMemo } from "react";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";

import { AppContext } from "context/app";
import { ModalContext } from "context/modal";
import { UserContext } from "context/user";
import { CartContext } from "context/cart";
import { AnalyticsSessionParamsContext } from "context/analyticsSessionParams";
import { useAppProvider } from "context/hooks/useAppProvider";
import { useUserProvider } from "context/hooks/useUserProvider";
import { useCartProvider } from "context/hooks/useCartProvider";
import { useModalProvider } from "context/hooks/useModalProvider";
import { useAnalyticsSessionParamsProvider } from "context/hooks/useAnalyticsSessionParamsProvider";
import { Ccm } from "@pepdirect/ui/ccm";
import { generateFontFaces } from "@pepdirect/helpers/fonts";

import "styles/leaflet.scss";
import "styles/main.scss";
import "styles/contact-form.scss";
import "styles/print.scss";
// MAIN CSS POINT
import "styles/sass/style.scss";
import "styles/custom-tab.scss";
import "react-image-lightbox/style.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import st from "./app.module.scss";

import { RockstarEnergyShopTheme } from "@pepdirect/ui/theme/RockstarEnergyShopTheme";
import { useFontTracking } from "@pepdirect/helpers/useFontTracking";
import { FontTrackingiFrame } from "@pepdirect/ui/fontTracking";
import { RootModal } from "components/Modals/Modal";
import App, {
  AppContext as NextAppContext,
  AppInitialProps,
  AppProps,
} from "next/app";

/* Importing and customizing nprogress */
import "styles/nprogress.scss";
import Head from "next/head";
import {
  META_TITLE,
  META_DESCRIPTION,
  META_DEFAULT_OG_IMAGE,
  CANONICAL_URL_BASE,
  PRIMARY_COLOR,
} from "constants/meta";

import { endpoints, isLocalEnv } from "config";
import { useLogPageProvider } from "hooks/useLogPageView";
import { LogContext } from "context/log";
import { ThemeProvider } from "styled-components";
import { addGitShaToWindow } from "@pepdirect/helpers/gitSha";
import client from "services/graphql/client";
import {
  CurrentUserDocument,
  CurrentUserQuery,
  FeatureFlagsDocument,
  FeatureFlagsQuery,
  FeatureFlagsQueryVariables,
  TenantDocument,
  TenantQuery,
  TenantQueryVariables,
} from "services/graphql/generated";
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { BodyClassContext } from "context/bodyClass";
import { useBodyClassProvider } from "context/hooks/useBodyClassProvider";
import MainWrapper from "components/MainWrapper";
import { isServerSide } from "helpers/routing";
import SnackbarProvider from "react-simple-snackbar";
import createSsrClient from "services/graphql/ssr-client";
import Script from "next/script";
addGitShaToWindow();

// Init progress bar
NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// Scroll to top after router.push();
Router.events.on("routeChangeComplete", (event) => {
  NProgress.done();

  // Do not scroll to top of Shop All page for filters
  const asPathName = event?.split("?")[0] || "";
  if (asPathName != "/shop-all") {
    window?.scrollTo(0, 0);
  }
});

interface RockstarEnergyAppInitialProps extends AppInitialProps {
  featureFlags: FeatureFlagsQuery;
  tenant: TenantQuery;
}

interface RockstarEnergyAppProps extends AppProps {
  featureFlags: FeatureFlagsQuery;
  tenant: TenantQuery;
}

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    trackertype?: string;
  }
}

RockstarEnergy.getInitialProps = async (
  appContext: NextAppContext
): Promise<RockstarEnergyAppInitialProps> => {
  let gqlClient: ApolloClient<NormalizedCacheObject>;
  let userId: CurrentUserQuery["currentUser"] | null = null;
  if (isServerSide(appContext)) {
    // by the if check above, we guarantee that appContext will have request in it.
    gqlClient = createSsrClient(appContext?.ctx?.req);
  } else {
    gqlClient = client;
  }

  try {
    const userData = await gqlClient.query<CurrentUserQuery>({
      query: CurrentUserDocument,
    });
    userId = userData?.data?.currentUser?.id || null;
    // eslint-disable-next-line no-empty
  } catch (e) {}

  // When we add cookies to the request for tenant and featureFlags queries,
  // if the cookie is not valid, then they return 401 unauthenticated error.
  // To prevent this, we detach cookies from the request before making request to them
  // if the user cookie is not valid. If cookie is not set, then this would not be a problem.
  if (!userId && isServerSide(appContext)) {
    gqlClient = createSsrClient(appContext?.ctx?.req, true);
  }

  const [appProps, featureFlagsData, tenantData] = await Promise.all([
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    App?.getInitialProps(appContext),
    gqlClient.query<FeatureFlagsQuery, FeatureFlagsQueryVariables>({
      query: FeatureFlagsDocument,
    }),
    gqlClient.query<TenantQuery, TenantQueryVariables>({
      query: TenantDocument,
    }),
  ]);

  return {
    ...appProps,
    featureFlags: featureFlagsData.data,
    tenant: tenantData.data,
  };
};

// Revisit this later if layout needs to change https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export default function RockstarEnergy({
  Component,
  pageProps,
  featureFlags,
  tenant,
}: RockstarEnergyAppProps): JSX.Element {
  const router = useRouter();

  const fonts = tenant.tenant?.brandConfig.trackingFonts || [];
  const title = tenant.tenant?.brandConfig.title || "Rockstar Energy";
  const trackFontResources = useFontTracking(fonts, title);

  useEffect(() => {
    // exposes pod hostname to browser window
    if (window) {
      window._pepdirect = {
        ...window._pepdirect,
      };
    }
  }, []);

  const featureFlagsMemo = useMemo(() => {
    return featureFlags?.featureFlags || [];
  }, [featureFlags]);

  const { appContextValue } = useAppProvider(featureFlagsMemo, tenant?.tenant);
  const { modalContextValue } = useModalProvider();
  const { userContextValue } = useUserProvider(client);
  const { cartContextValue } = useCartProvider(
    client,
    userContextValue.currentUserId
  );
  const { useLogPageView, hasLoggedPageView } = useLogPageProvider(
    userContextValue.currentUserId
  );
  const { bodyContextValue } = useBodyClassProvider();
  const { analyticsParamsValue } = useAnalyticsSessionParamsProvider();

  return (
    <>
      <Head>
        {/* Meta tags not intended to change */}
        <meta name="theme-color" content={PRIMARY_COLOR} />
        <meta name="og:site_name" content={META_TITLE} />
        <meta name="og:price:currency" content="USD" />
        {/* Meta tags */}
        <meta name="og:title" content={META_TITLE} key="og:title" />
        <meta name="description" content={META_DESCRIPTION} key="description" />
        <meta
          name="og:description"
          content={META_DESCRIPTION}
          key="og:description"
        />
        <meta name="og:image" content={META_DEFAULT_OG_IMAGE} key="og:image" />
        <meta name="og:type" content="website" key="og:type" />
        <meta name="og:url" content={CANONICAL_URL_BASE} key="og:url" />
      </Head>
      <Ccm cdn={endpoints.cdn} />
      {router.isPreview && (
        <div className={st.PreviewContainer}>
          <div className={st.PreviewTitle}>You are in a preview mode</div>
          <div className={st.PreviewSubContainer}>
            <a
              href="https://rockstar-energy.admin.datocms.com/"
              className={st.PreviewButton}
            >
              Go to DatoCMS
            </a>
            <a
              href={`/api/exit-preview?slug=${router.asPath}`}
              className={st.PreviewButton}
            >
              Exit preview mode
            </a>
          </div>
        </div>
      )}
      <style
        dangerouslySetInnerHTML={{
          __html: generateFontFaces(
            tenant.tenant?.brandConfig?.customizations.fonts || []
          ),
        }}
      />
      {trackFontResources.map((resource) => (
        <FontTrackingiFrame
          resource={resource}
          key={resource}
          isLocalEnv={isLocalEnv}
        />
      ))}
      <ApolloProvider client={client}>
        <AppContext.Provider value={appContextValue}>
          <BodyClassContext.Provider value={bodyContextValue}>
            <ThemeProvider theme={RockstarEnergyShopTheme}>
              <ModalContext.Provider value={modalContextValue}>
                <UserContext.Provider value={userContextValue}>
                  <CartContext.Provider value={cartContextValue}>
                    <LogContext.Provider
                      value={{ useLogPageView, hasLoggedPageView }}
                    >
                      <AnalyticsSessionParamsContext.Provider
                        value={analyticsParamsValue}
                      >
                        <SnackbarProvider>
                          <MainWrapper
                            Component={Component}
                            pageProps={pageProps}
                          />
                        </SnackbarProvider>
                        <RootModal />
                      </AnalyticsSessionParamsContext.Provider>
                    </LogContext.Provider>
                  </CartContext.Provider>
                </UserContext.Provider>
              </ModalContext.Provider>
            </ThemeProvider>
          </BodyClassContext.Provider>
        </AppContext.Provider>
      </ApolloProvider>
      <script
        dangerouslySetInnerHTML={{
          __html: `document.body.classList.remove("no-js");`,
        }}
      />{" "}
      <script
        dangerouslySetInnerHTML={{
          __html: `	if ( -1 !== navigator.userAgent.indexOf( 'MSIE' ) || -1 !== navigator.appVersion.indexOf( 'Trident/' ) ) {
		document.body.classList.add( 'is-IE' );
	}`,
        }}
      />
      <Script
        id={"trustarc"}
        className="trustecm"
        /* eslint-disable-next-line react/no-unknown-property */
        trackertype="advertising"
        type="text/plain"
      >
        {`document.getElementById('youtube-container').innerHTML = '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/ASjjcXsa8og" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe><br/><span className="taglabelnotice">Advertising custom tag loaded</span>';`}
      </Script>
    </>
  );
}
