import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { CartContext } from "context/cart";
import { ModalContext } from "context/modal";
import { UserContext } from "context/user";
import { ZipCodeContext } from "context/zipCode";
import { Button } from "@pepdirect/ui/button";
import { Heading } from "@pepdirect/ui/heading";
import {
  generateHashedId,
  logProductImpression,
} from "@pepdirect/helpers/analyticsLogger";
import { PopupItem } from "components/PopupItem";
import st from "./upsellModal.module.scss";
import client from "services/graphql/client";
import { endpoints } from "config";
import { useRouter } from "next/router";
import { UpsellModalProps } from "types/modal";
import {
  useFindRecommendationsQuery,
  ItemByGtinQuery,
  ItemByGtinQueryVariables,
  ItemByGtinDocument,
} from "services/graphql/generated";
import { UpsellItem } from "@pepdirect/ui/cart/UpsellItem";
import { Product } from "@pepdirect/shared/types";

export function UpsellModal({
  products,
  priceItem,
}: UpsellModalProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const viewCartRef = useRef<HTMLAnchorElement | HTMLButtonElement | null>(
    null
  );
  const continueShopRef = useRef<HTMLAnchorElement | HTMLButtonElement | null>(
    null
  );
  const cmsProductsSlugs: { gtin: string; slug: string }[] = useMemo(
    () =>
      products?.map((product) => ({
        gtin: product.gtin as string,
        slug: product.slug as string,
      })),
    [products]
  );

  const { close } = useContext(ModalContext);
  const { cart, updateCartItem } = useContext(CartContext);
  const router = useRouter();
  const [upsellItems, setUpsellItems] = useState<
    NonNullable<ItemByGtinQuery["itemByGtin"]>[]
  >([]);
  const { currentUserId } = useContext(UserContext);
  const { blockedItems } = useContext(ZipCodeContext);

  const {
    data: allFindRecommendationsQuery,
    refetch: refetchRecommendedItems,
  } = useFindRecommendationsQuery({
    variables: {
      cartId: cart?.id,
      numberRequested: 150,
    },
  });

  const addDataEventIncludeToChildren = useCallback((parent: HTMLElement) => {
    for (let i = 0; i < parent.children.length; i++) {
      const childElement = parent.children[i] as HTMLElement;
      if (!childElement.getAttribute("data-track-event")) {
        childElement.setAttribute("data-event-include", "");
        addDataEventIncludeToChildren(childElement);
      }
    }
  }, []);

  const addDataEventInclude = useCallback(() => {
    const upsellDiv = containerRef.current;
    if (upsellDiv) {
      addDataEventIncludeToChildren(upsellDiv);
    }
    const continueShopButton = continueShopRef.current;
    if (continueShopButton) {
      addDataEventIncludeToChildren(continueShopButton);
    }
    const viewCartButton = viewCartRef.current;
    if (viewCartButton) {
      addDataEventIncludeToChildren(viewCartButton);
    }
  }, [addDataEventIncludeToChildren]);

  useEffect(() => {
    addDataEventInclude();
  }, [addDataEventInclude, upsellItems]);

  useEffect(() => {
    const getUpsellItems = async () => {
      const recommendedItems = [];
      for (const upsellGtin of allFindRecommendationsQuery?.findRecommendations ||
        []) {
        try {
          const { data } = await client.query<
            ItemByGtinQuery,
            ItemByGtinQueryVariables
          >({
            query: ItemByGtinDocument,
            variables: { gtin: upsellGtin?.gtin as string },
          });
          if (data.itemByGtin) recommendedItems.push(data.itemByGtin);
        } catch (e) {
          // logged in errorLink
        }
      }
      const firstTwoItems = recommendedItems.slice(0, 2);
      firstTwoItems.length &&
        logProductImpression(
          "",
          firstTwoItems.map((item) => ({
            _id: item?.id || "",
            gtin: item?.gtin || "",
            itemTitle: item?.title?.toLocaleLowerCase() || "",
            itemPrice: item?.price ? item.price / 100 : null,
            itemDesignerArtistBrandName:
              item?.itemDesignerArtistBrandName || null,
            itemDimensions: item?.itemDimensions || null,
          })) as Product[],
          "product|modal|upsell|you may also like",
          generateHashedId("product|modal|upsell|you may also like")
        );
      setUpsellItems(firstTwoItems);
    };

    getUpsellItems();
  }, [allFindRecommendationsQuery]);

  useEffect(() => {
    refetchRecommendedItems();
  }, [cart?.items, refetchRecommendedItems]);

  const isUpsellInCart = useCallback(
    (productId: string) => {
      return !!cart?.items?.some(
        (cartItem) => cartItem.product.id === productId
      );
    },
    [cart]
  );

  const goToCart = () => {
    close(null);
    router.push("/cart");
  };

  return (
    <div
      className={st.wrapper}
      ref={containerRef}
      data-track-event="visible"
      data-element-action="view"
      data-element-block="window"
      data-element-name="modal"
      data-element-item="upsell|you may also like"
      data-element-type="informational component"
      data-element-target={`${window.location.href}/v/modal-window`}
    >
      <Heading level="h2" size="m" className={st.modalHeader}>
        Added to your cart
      </Heading>
      <PopupItem priceItem={priceItem} />
      <Button
        forwardedRef={viewCartRef}
        data-track-event="click"
        data-element-action="close"
        data-element-block="window"
        data-element-name="modal"
        data-element-item="upsell|you may also like"
        data-element-type="informational component"
        data-element-target={`${window.location.href}/v/modal-window`}
        data-link_id="view-cart-button"
        data-link_text="View cart & checkout"
        onClick={goToCart}
      >
        View cart & checkout
      </Button>
      <Button
        forwardedRef={continueShopRef}
        data-track-event="click"
        data-element-action="close"
        data-element-block="window"
        data-element-name="modal"
        data-element-item="upsell|you may also like"
        data-element-type="informational component"
        data-element-target={`${window.location.href}/v/modal-window`}
        data-link_id="continue-shopping-button"
        data-link_text="continue shopping"
        className={st.buttonOutline}
        type="tertiary"
        onClick={() => {
          close(null);
        }}
      >
        Continue Shopping
      </Button>
      {upsellItems?.length > 0 && (
        <div className={st.recommendations}>
          <Heading level="h3" size="m">
            You may also like
          </Heading>
          <div className={st.upsellContainer}>
            {upsellItems.map((upsellItem, index) => (
              <div key={`upsell-item-${index}`}>
                <UpsellItem
                  key={upsellItem.gtin}
                  cmsProducts={cmsProductsSlugs}
                  blockedItems={blockedItems}
                  currentUserId={currentUserId}
                  product={upsellItem}
                  index={index + 1}
                  itemImgCdn={endpoints.productsImgCdn}
                  isInCart={isUpsellInCart(upsellItem._id)}
                  updateCartItem={updateCartItem}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
