import { AppProps } from "next/app";
import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { BodyClassContext } from "context/bodyClass";
import NewsletterModal from "components/NewsletterModal";
import {
  getNewsletterStatusFromCookie,
  saveNewsletterCookie,
} from "helpers/newsletter";
import { useRouter } from "next/router";

type MainWrapperProps = Pick<AppProps, "Component" | "pageProps">;

const MainWrapper: (props: MainWrapperProps) => JSX.Element = ({
  Component,
  pageProps,
}) => {
  const [newsletterModalOpen, setNewsletterModalOpen] =
    useState<boolean>(false);
  const { bodyClass } = useContext(BodyClassContext);
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      const newsletterCookie = getNewsletterStatusFromCookie();
      if (
        router?.pathname !== "/" &&
        router?.pathname !== "/events" &&
        !newsletterCookie
      ) {
        saveNewsletterCookie();
        setNewsletterModalOpen(true);
      }
    }, 10000);
  }, [router?.pathname]);

  return (
    <>
      <div className={cx("body-div", bodyClass?.join(" ") || "")}>
        <Component {...pageProps} />
      </div>
      <NewsletterModal
        isOpen={newsletterModalOpen}
        close={() => setNewsletterModalOpen(false)}
      />
    </>
  );
};

export default MainWrapper;
