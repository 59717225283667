import React, { FC } from "react";
import styled from "styled-components";
import getConfig from "next/config";
import { InternalLink } from "../../link/InternalLink";
import { Device } from "../../../helpers/screenSizes";
import { ItemImageProps } from "../common/types";
import { getCloudflareImageUrl } from "@pepdirect/helpers/cloudflareImages";
import { getSlugOfCmsProducts } from "../common/helpers";

const { publicRuntimeConfig } = getConfig();

interface WrapperProps {
  isLink?: boolean;
  small?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  width: 60px;
  height: 60px;
  margin-right: 15px;
  flex-shrink: 0;
  cursor: ${({ isLink }) => (isLink ? "pointer" : "auto")};

  ${({ small }) =>
    !small &&
    `
    @media ${Device.laptop} {
      width: 200px;
      height: 200px;
      margin-right: 20px;
    }
  `}
`;

const ImageWrapper = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const Image = styled.img<{ useCdn: boolean }>`
  /* Hack to emulate objectFit: contain in IE: https://stackoverflow.com/a/41226965 */
  max-width: 100%;
  max-height: 100%;
  /* alt text styling */
  word-break: break-word;
  ${({ useCdn }) => useCdn && `width: 300px;`}
`;

export const ItemImage: FC<ItemImageProps> = ({
  productId,
  productTitle,
  productSlug,
  itemImgSrc = "",
  fallbackUrl,
  isLink = true,
  small,
  cmsProducts,
  productGtin,
  useProductCdn = true,
}) => {
  // Width 300 so it looks ok at retina as well.
  const src = getCloudflareImageUrl(
    publicRuntimeConfig.productsImgCdn,
    itemImgSrc,
    {
      width: 300,
    }
  );
  const alt = `packaging for ${productTitle}`;

  const cmsProductSlug = getSlugOfCmsProducts(
    cmsProducts || [],
    productGtin || ""
  );

  const pdpHref = `${fallbackUrl}/product${cmsProductSlug ? "s" : ""}/${
    cmsProductSlug || productSlug || productId
  }`;

  return (
    <Wrapper isLink={isLink} small={small}>
      {isLink ? (
        <InternalLink href={pdpHref}>
          <ImageWrapper>
            <Image
              src={useProductCdn ? src : itemImgSrc}
              alt={alt}
              useCdn={useProductCdn}
            />
          </ImageWrapper>
        </InternalLink>
      ) : (
        <ImageWrapper>
          <Image
            src={useProductCdn ? src : itemImgSrc}
            alt={alt}
            useCdn={useProductCdn}
          />
        </ImageWrapper>
      )}
    </Wrapper>
  );
};
