import { OptionsType } from "react-select";
import { CartProduct } from "@pepdirect/shared/types";
import { CartFragment } from "../../../graphql/generated";
import { ReactSelectDropdownOption } from "../../dropdown/types";
import { CmsProductGtinWithSlug } from "./types";

export const getQuantityOptions = (
  product: Partial<CartProduct>,
  maxCartQty: number
): OptionsType<ReactSelectDropdownOption> => {
  const total = product.inventoryQty
    ? Math.min(product.inventoryQty, maxCartQty)
    : maxCartQty;

  const options = range(0, total).map((num) => {
    return {
      value: num.toString(),
      label: num.toString(),
    };
  });

  return options;
};

/**
 * Helper function to return an array of numbers from start to end.
 * e.g., range(1, 4) gets [1, 2, 3, 4]
 * @param start
 * @param end
 */
export function range(start: number, end: number): number[] {
  return [...Array(end - start + 1)].map((_i, idx) => idx + start);
}

export const getSlugOfCmsProducts = (
  cmsProducts: CmsProductGtinWithSlug[],
  pepDirectProductGtin: string
) =>
  cmsProducts?.find((cmsProduct) => cmsProduct.gtin === pepDirectProductGtin)
    ?.slug;

export const getTotalSavings = (cart: CartFragment) => {
  if (!cart?.items?.length) return 0;

  return (
    cart.items.reduce(
      (savings: number, item: CartFragment["items"][number]) => {
        const { perUnitPrice, quantity, strikethroughUnitPrice } = item;
        if (strikethroughUnitPrice) {
          return savings + (strikethroughUnitPrice - perUnitPrice) * quantity;
        } else {
          return savings;
        }
      },
      0
    ) / 100
  );
};
