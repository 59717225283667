import React, { useContext, useEffect } from "react";
import Modal from "react-modal";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { ModalContext } from "context/modal";
import st from "./modal.module.scss";

/* Modals */
import { UpsellModalProps } from "types/modal";
import { CheckoutSubscriptionModal } from "./CheckoutSubscriptionModal";
import { UpsellModal } from "./UpsellModal";
import { UserContext } from "context/user";

export function RootModal(): JSX.Element {
  const { currentUserId } = useContext(UserContext);
  const { isOpen, close, type, props } = useContext(ModalContext);
  useEffect(() => {
    if (typeof window !== "undefined") {
      Modal.setAppElement("#__next");
    }
    // Cleanup just in case
    return clearAllBodyScrollLocks;
  }, []);
  return (
    <Modal
      onAfterClose={clearAllBodyScrollLocks}
      // Still need to check ref because the ref returns null while modal is closing.
      overlayRef={(ref) =>
        ref && disableBodyScroll(ref, { reserveScrollBarGap: true })
      }
      isOpen={isOpen}
      onRequestClose={() => close(currentUserId)}
      className={st.modal}
      overlayClassName={st.overlay}
      closeTimeoutMS={250}
    >
      <button
        className={st.close}
        onClick={() => {
          close(currentUserId);
        }}
        aria-label="Close"
        {...(type === "UPSELL_MODAL" && {
          "data-track-event": "click",
          "data-element-action": "close",
          "data-element-block": "window",
          "data-element-name": "modal",
          "data-element-item": "upsell|you may also like",
          "data-element-type": "informational component",
          "data-element-target": `${window.location.href}/v/modal-window`,
          "data-link_id": "exit-button",
          "data-link_text": "continue shopping",
        })}
      >
        <span
          {...(type === "UPSELL_MODAL" && { "data-event-include": "" })}
          aria-hidden="true"
        >
          ×
        </span>
      </button>
      {/* TODO: lazy-load? */}
      {type === "CHECKOUT_SUBSCRIPTION_MODAL" && <CheckoutSubscriptionModal />}
      {type === "UPSELL_MODAL" && (
        <UpsellModal {...(props as UpsellModalProps)} />
      )}
    </Modal>
  );
}
