import React, { FC } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";

const StyledDiv = styled.div<{ width?: string; height?: string }>`
  background-color: ${({ theme }) => theme.color.primary || colors["gray"]};
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: 0 22px;
  border-bottom-right-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

interface OrderRowEarMarkContainerProps
  extends React.HTMLProps<HTMLDivElement> {
  width?: string;
  height?: string;
}

export const OrderRowEarmarkContainer: FC<OrderRowEarMarkContainerProps> = ({
  className,
  height,
  width,
  children,
}) => {
  return (
    <StyledDiv className={className} height={height} width={width}>
      {children}
    </StyledDiv>
  );
};
