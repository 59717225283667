import React, { FC } from "react";
import styled, { css } from "styled-components";
import { ItemPriceProps } from "../common/types";
import { convertFromCentsToDollars } from "../../../helpers/currency";
import { colors } from "../../../styles/variables";

const priceStyles = css`
  font-size: 14px;
  line-height: 18px;
`;

const Price = styled.div`
  ${priceStyles};
`;

const OldPrice = styled.span`
  color: ${colors.gray};
  text-decoration: line-through;
  padding-left: 2px;
`;

const NewPrice = styled.span`
  ${priceStyles};
`;

const StyledItemPrice = styled.div<{ small: boolean }>`
  ${OldPrice} {
    font-size: ${({ small }) => (small ? "11px" : "12px")};
  }
`;

export const ItemPrice: FC<ItemPriceProps> = ({
  perUnitPrice,
  small = false,
  strikethroughUnitPrice,
}) => {
  const getDisplayPrices = () => {
    if (strikethroughUnitPrice) {
      return (
        <div>
          <NewPrice>{convertFromCentsToDollars(perUnitPrice)} each</NewPrice>
          <OldPrice>
            Reg. {convertFromCentsToDollars(strikethroughUnitPrice)}
          </OldPrice>
        </div>
      );
    } else if (perUnitPrice) {
      return <Price>{convertFromCentsToDollars(perUnitPrice)} each</Price>;
    }

    return null;
  };

  return <StyledItemPrice small={small}>{getDisplayPrices()}</StyledItemPrice>;
};
