import { ThemeInterface, themeNames } from "../theme";
import { colors } from "../../styles/variables";
import {
  FontNames,
  HexColor,
  TextTransform,
  fontNames,
} from "@pepdirect/shared/types";
type RockstarColorNames =
  | "rockstarGold"
  | "black"
  | "background"
  | "sandyBackground"
  | "darkSandyBackground";

const ROCKSTAR_COLORS: Record<RockstarColorNames, HexColor> = {
  rockstarGold: "#CF9C43",
  black: "#2C2925",
  background: "#FAF6E8",
  sandyBackground: "#EFE9D6",
  darkSandyBackground: "#E8E0C4",
};

const SHARED_HEADING_STYLES = {
  color: ROCKSTAR_COLORS.black,
  fontFamily: "font-secondary" as FontNames,
  textTransform: "uppercase" as TextTransform,
};

export const RockstarEnergyShopTheme: ThemeInterface = {
  name: themeNames.RockstarEnergyShop,
  borderRadius: "10px",
  color: {
    primary: ROCKSTAR_COLORS.black,
    secondary: ROCKSTAR_COLORS.black,
    tertiary: ROCKSTAR_COLORS.black,
    accent: ROCKSTAR_COLORS.black,
    disabled: colors.gray,
    action: colors.lightGreen,
    error: colors.red,
    text: ROCKSTAR_COLORS.black,
  },
  button: {
    primary: {
      color: ROCKSTAR_COLORS.rockstarGold,
      textColor: ROCKSTAR_COLORS.black,
    },
    secondary: {
      color: ROCKSTAR_COLORS.rockstarGold,
      textColor: ROCKSTAR_COLORS.black,
      solidOnHover: true,
    },
    tertiary: {
      color: ROCKSTAR_COLORS.black,
      hollow: true,
    },
    cancel: { color: colors.mediumGray },
    error: { color: colors.red },
    text: { color: ROCKSTAR_COLORS.black },
    disabled: { hollow: true },
    fontFamily: "font-secondary",
    textTransform: "uppercase",
    borderRadius: "0",
  },
  typography: {
    body: {
      fontSize: "16px",
      color: colors.black,
      fontFamily: fontNames["font-primary"],
    },
    heading: {
      xxl: {
        fontSize: {
          desktop: "44px",
          mobile: "36px",
        },
        ...SHARED_HEADING_STYLES,
      },
      xl: {
        fontSize: {
          desktop: "30px",
          mobile: "26px",
        },
        ...SHARED_HEADING_STYLES,
      },
      l: {
        fontSize: {
          desktop: "20px",
          mobile: "18px",
        },
        color: colors.black,
        fontFamily: "font-secondary" as FontNames,
        textTransform: "uppercase" as TextTransform,
      },
      m: {
        fontSize: {
          desktop: "20px",
          mobile: "18px",
        },
        fontFamily: "font-secondary",
        color: colors.black,
      },
      s: {
        fontSize: {
          desktop: "18px",
          mobile: "16px",
        },
        ...SHARED_HEADING_STYLES,
      },
      xs: {
        fontSize: {
          desktop: "13px",
          mobile: "13px",
        },
        ...SHARED_HEADING_STYLES,
      },
      xxs: {
        fontSize: {
          desktop: "13px",
          mobile: "13px",
        },
        fontWeight: "400",
        color: ROCKSTAR_COLORS.black,
      },
    },
  },
  dividerColor: ROCKSTAR_COLORS.darkSandyBackground,
  header: {
    portal: {
      backgroundColor: ROCKSTAR_COLORS.sandyBackground,
    },
    shop: {
      backgroundColor: ROCKSTAR_COLORS.sandyBackground,
    },
    simple: {
      checkout: {
        backgroundColor: ROCKSTAR_COLORS.sandyBackground,
      },
      portal: {
        backgroundColor: ROCKSTAR_COLORS.sandyBackground,
      },
    },
  },
  footer: {
    backgroundColor: ROCKSTAR_COLORS.sandyBackground,
  },
  priceAccentColor: colors.black,
  cart: {
    buttonFontSize: "13px",
    isFullWidth: true,
    sectionBackgroundColor: ROCKSTAR_COLORS.sandyBackground,
    freeShipSection: {
      progressBarColor: colors.lightGreen,
      progressBarBackgroundColor: colors.accent,
    },
  },
};
