import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { ThemeContext } from "styled-components";

// interfaces, helpers, & children components
import { Button } from "../button/Button";
import { UpsellItemProps } from "./common/types";
import { InternalLink } from "../link/InternalLink";
import { Heading } from "../heading/Heading";
import {
  generateHashedId,
  logProductTileBuyButtonClickEvent,
} from "@pepdirect/helpers/analyticsLogger";
import { getCloudflareImageUrl } from "@pepdirect/helpers/cloudflareImages";
import { colors } from "../../styles/variables";
import { convertFromCentsToDollars } from "../../helpers/currency";
import { Device } from "../../helpers/screenSizes";
import { Product } from "@pepdirect/shared/types";
import { getSlugOfCmsProducts } from "./common/helpers";

const StyledUpsellItem = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ImageWrapper = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 80px;
  height: 80px;
`;

const Image = styled.img`
  /* Hack to emulate objectFit: contain in IE: https://stackoverflow.com/a/41226965 */
  max-width: 100%;
  max-height: 100%;
  /* alt text styling */
  word-break: break-word;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-left: 20px;

  @media ${Device.notLaptop} {
    margin-left: 20px;
  }
`;

const PriceAndButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;

  @media ${Device.tablet} {
    margin-top: 10px;
    flex-direction: column;
  }
`;

const Price = styled.div`
  color: ${({ theme }) => theme.priceAccentColor || colors.green};
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  border-radius: 0;
  padding: 0 10px;
  height: 30px;
  min-width: 70px;
  line-height: 1.7;

  @media ${Device.tablet} {
    margin-top: 10px;
  }
`;

export const UpsellItem: FC<UpsellItemProps> = ({
  blockedItems,
  currentUserId,
  fallbackUrl = "",
  itemImgCdn = "",
  product,
  updateCartItem,
  isInCart = false,
  cmsProducts,
  index,
}) => {
  const theme = useContext(ThemeContext);
  // state to track the adding process
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const addDataEventIncludeToChildren = useCallback((parent: HTMLElement) => {
    for (let i = 0; i < parent.children.length; i++) {
      const childElement = parent.children[i] as HTMLElement;
      childElement.setAttribute("data-event-include", "");
      addDataEventIncludeToChildren(childElement);
    }
  }, []);

  const addDataEventInclude = useCallback(() => {
    const upsellDiv = upsellItemRef.current;
    if (upsellDiv) {
      addDataEventIncludeToChildren(upsellDiv);
    }
  }, [addDataEventIncludeToChildren]);

  useEffect(() => {
    addDataEventInclude();
  }, [addDataEventInclude]);
  const upsellItemRef = useRef<HTMLDivElement>(null);
  const {
    itemPrice,
    itemSlug,
    itemImgUrl,
    itemTitle,
    isOutOfStock,
    inventoryQty,
    categories,
    gtin,
  } = product;
  const price = convertFromCentsToDollars(itemPrice || 0);
  // Width 160 so it looks ok at retina as well.
  const src = getCloudflareImageUrl(itemImgCdn, itemImgUrl || "", {
    width: 160,
  });
  const cmsProductSlug = getSlugOfCmsProducts(
    cmsProducts || [],
    product.gtin || ""
  );

  const pdpHref = `${fallbackUrl}/product${cmsProductSlug ? "s" : ""}/${
    cmsProductSlug || itemSlug || product._id
  }`;
  const alt = `packaging for ${itemTitle}`;

  const isItemUnavailable = useCallback(
    (id: string) => {
      return blockedItems?.includes(id);
    },
    [blockedItems]
  );

  const itemListName = "product|modal|upsell|you may also like";
  const itemListId = generateHashedId(itemListName);

  const handleAddToCart = () => {
    setIsAdding(true);
    logProductTileBuyButtonClickEvent(
      currentUserId,
      product as Product,
      1,
      itemListId,
      itemListName,
      index + 1
    );

    updateCartItem({
      productId: product._id,
      quantity: 1,
      subscriptionIntervalInDays: null,
    }).finally(() => setIsAdding(false)); // reset adding state when operation is complete
  };

  return (
    <StyledUpsellItem
      ref={upsellItemRef}
      data-track-event="select_item"
      data-event-category="ecommerce"
      data-event-action="product click"
      data-event-label={`${itemTitle?.toLowerCase() || ""}|${
        window.location.href
      }`}
      data-track-value={itemPrice ? itemPrice / 100 : 0}
      data-item-affiliation="one-time"
      data-item-brand="rockstar-energy"
      data-item-category1={
        categories?.[0]?.level1 ||
        categories?.[0]?.level2 ||
        categories?.[0]?.level3 ||
        ""
      }
      data-item-listid={itemListId}
      data-item-listname={itemListName}
      data-item-listindex={index.toString()}
      data-item-id={gtin || ""}
      data-item-name={itemTitle?.toLowerCase() || ""}
    >
      <InternalLink href={pdpHref}>
        <ImageWrapper>
          <Image src={src} alt={alt} />
        </ImageWrapper>
      </InternalLink>

      <DetailsWrapper>
        <InternalLink href={pdpHref}>
          <Heading level="h2" size="xs">
            {product.itemTitle}
          </Heading>
        </InternalLink>

        <PriceAndButton>
          <Price>{price}</Price>

          {isItemUnavailable(product._id) || isOutOfStock || !inventoryQty ? (
            <StyledButton disabled>Out of stock</StyledButton>
          ) : (
            <StyledButton
              disabled={isInCart || isAdding}
              loading={isAdding}
              onClick={handleAddToCart}
              fontSize={theme.cart?.buttonFontSize}
            >
              {isInCart ? "Added" : "+ Add"}
            </StyledButton>
          )}
        </PriceAndButton>
      </DetailsWrapper>
    </StyledUpsellItem>
  );
};
