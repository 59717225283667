import Cookies from "universal-cookie";
import { endpoints, cookies } from "config";
import URI from "urijs";

const cookie = new Cookies();
const newsLetterCookieOptions = {
  path: "/",
  secure: cookies.secureCookie,
  domain: URI(endpoints.web).domain(),
  // Expired in 24 hours so that we can show the modal again
  maxAge: 24 * 60 * 60,
};

export const getNewsletterStatusFromCookie = () =>
  cookie.get(cookies.newsletterCookie);

export const saveNewsletterCookie = () => {
  // Attempt to remove the cookie set w/o a proper domain first,
  // otherwise the set will fail.
  cookie.remove(cookies.newsletterCookie);
  cookie.set(cookies.newsletterCookie, "true", newsLetterCookieOptions);
};

export const deleteNewsletterCookie = () => {
  cookie.remove(cookies.newsletterCookie, newsLetterCookieOptions);
  // Attempt to remove cookies that may have been set on the wrong domain
  cookie.remove(cookies.newsletterCookie);
};
