import { useContext, useEffect, useState } from "react";
import {
  logCartView,
  logPageView,
  logProductViewedEvent,
} from "@pepdirect/helpers/analyticsLogger";
import {
  LogContextValueInterface,
  Cart,
  Product,
  LogPageViewParams,
} from "@pepdirect/shared/types";
import { isProductionReady } from "config";
import TagManager from "react-gtm-module";
import { AnalyticsSessionParamsContext } from "context/analyticsSessionParams";

export function useLogPageProvider(
  currentUserId: string | null
): LogContextValueInterface {
  const [hasLoggedPageView, setHasLoggedPageView] = useState(false);
  /**
   * Wrapper for logPageView, so we don't need to
   * write useEffect on every page.
   * @param key some unique id to identify page,
   * events are expected in a specific order given below
   * pageView -> gtm.js -> productView/cartView
   * for example, page url/slug/whatnot,
   * otherwise it won't trigger when for example
   * route changes from/kit/kit-1 to /kit/kit-2
   */
  const useLogPageView = (
    params: LogPageViewParams,
    product?: Product | null,
    cart?: Cart | null,
    key?: string
  ): void => {
    const [pageViewLoaded, setPageViewLoaded] = useState(false);
    const { analyticsSessionParams } = useContext(
      AnalyticsSessionParamsContext
    );
    const loadGtm = (shouldLoadGtm: boolean) => {
      isProductionReady &&
        shouldLoadGtm &&
        TagManager.initialize({ gtmId: "GTM-WFFQNGH" });
    };
    useEffect(() => {
      if (analyticsSessionParams) {
        logPageView(
          currentUserId,
          { ...params, sessionAtributes: analyticsSessionParams },
          "rockstar energy",
          loadGtm
        );
        setPageViewLoaded(true);
        setHasLoggedPageView(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, analyticsSessionParams]);

    useEffect(() => {
      if (cart && pageViewLoaded) {
        logCartView(currentUserId, cart);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart, pageViewLoaded]);

    useEffect(() => {
      if (product && pageViewLoaded) {
        logProductViewedEvent(currentUserId, product);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, pageViewLoaded]);
  };

  return { useLogPageView, hasLoggedPageView };
}
