import { useState, useEffect } from "react";
import {
  ApolloClient,
  NormalizedCacheObject,
  useLazyQuery,
} from "@apollo/client";
import {
  getCartIdFromCookie,
  saveCartCookie,
  deleteCartCookie,
} from "helpers/cart";
import {
  CartQuery,
  CartFragment,
  CartDocument,
  CartQueryVariables,
  GetCartFromUserQuery,
  GetCartFromUserDocument,
  GetCartFromUserQueryVariables,
  useUpdateCartItemMutation,
  useAssociateCartToUserMutation,
} from "services/graphql/generated";
import { CartItemUpdateDto } from "@pepdirect/shared/types";
import { CartContextInterface } from "../cart";

export function useCartProvider(
  client: ApolloClient<NormalizedCacheObject>,
  currentUserId: string | null
): {
  cartContextValue: CartContextInterface;
} {
  const [cart, setCart] = useState<CartFragment | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [updateCartItem] = useUpdateCartItemMutation({ client });
  const [associateCartToUser] = useAssociateCartToUserMutation({ client });
  const [getCartFromUserId] = useLazyQuery<
    GetCartFromUserQuery,
    GetCartFromUserQueryVariables
  >(GetCartFromUserDocument, { client });
  const [getCartFromId] = useLazyQuery<CartQuery, CartQueryVariables>(
    CartDocument,
    { client }
  );

  const fetchCart = async (cartIdFromCookie: string) => {
    try {
      const { data } = await getCartFromId({
        variables: { id: cartIdFromCookie },
      });
      setCart(data?.cart || null);
    } catch {
      setCart(null);
    } finally {
      setLoading(false);
    }
  };

  const getCartFromUser = async () => {
    try {
      const { data } = await getCartFromUserId();
      setCart(data?.currentUser?.cart || null);
    } catch {
      setCart(null);
    } finally {
      setLoading(false);
    }
  };

  const refreshCart = () => {
    const cartIdFromCookie = getCartIdFromCookie();

    // get cart with a cart id
    if (cartIdFromCookie) {
      fetchCart(cartIdFromCookie);
    }

    // no cart id: get cart from current user
    if (!cartIdFromCookie && currentUserId) {
      getCartFromUser();
    }

    // no cart id or current user: no cart
    if (!cartIdFromCookie && !currentUserId) {
      setCart(null);
      setLoading(false);
    }
  };

  const updateCart = async (dto: CartItemUpdateDto) => {
    if (!dto.productId) throw new Error("No productId, could not add product.");
    const cartIdFromCookie = getCartIdFromCookie();
    const { productId, quantity, subscriptionIntervalInDays } = dto;
    try {
      const updatedCart = await updateCartItem({
        variables: {
          id: cartIdFromCookie,
          itemId: productId,
          quantity,
          subscriptionIntervalInDays,
        },
      });
      setCart(updatedCart.data?.updateCartItem || null);
    } catch (e) {
      // logged in errorLink
    } finally {
      setLoading(false);
    }
  };

  // refresh cart on currentUserId and tab changes
  useEffect(() => {
    refreshCart();

    /* Event listener to refresh cart after changing tabs */
    /* TODO: debounce? */
    const refreshIfTabHasChanged = () => {
      if (document?.visibilityState === "visible") refreshCart();
    };
    window?.addEventListener("visibilitychange", refreshIfTabHasChanged);
    return function removeFocusRefresh() {
      window?.removeEventListener("visibilitychange", refreshIfTabHasChanged);
    };
    // we don't want refreshCart in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  // if an unauthenticated user creates a cart and then logs in afterward,
  // associate that cart to the logged-in user
  useEffect(() => {
    const handleAssociateCartToUser = async () => {
      try {
        const { data } = await associateCartToUser({
          variables: { cartId: cart?.id },
        });
        if (data?.associateCartToUser?.user) {
          setCart(data?.associateCartToUser);
          setLoading(false);
        }
      } catch (e) {
        // logged in errorLink
      }
    };
    if (cart && !cart.user && currentUserId) {
      handleAssociateCartToUser();
    }
    // we don't want associateCartToUser in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, currentUserId]);

  // update cookies depending on cart id changes
  useEffect(() => {
    if (!loading) {
      if (cart?.id) {
        if (cart.id !== getCartIdFromCookie()) {
          saveCartCookie(cart.id);
        }
      } else {
        deleteCartCookie();
      }
    }
  }, [cart?.id, loading]);

  // TODO: consider using recoil.js when it is stable
  const cartContextValue: CartContextInterface = {
    cart,
    loading,
    refreshCart,
    updateCartItem: (dto: CartItemUpdateDto) => updateCart(dto),
    getSize: () => {
      return loading
        ? undefined
        : cart?.items
            ?.map(({ quantity }) => quantity)
            .reduce((a, b) => a + b, 0) || 0;
    },
  };

  return { cartContextValue };
}
