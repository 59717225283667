/* eslint-disable @next/next/no-img-element */
import React, { FC, useMemo } from "react";
import { ItemSubDetails } from "@pepdirect/ui/cart/ItemSubDetails";
import { ItemImage } from "@pepdirect/ui/cart/ItemImage";
import { ItemPrice } from "@pepdirect/ui/cart/ItemPrice";
import { ItemTitle } from "@pepdirect/ui/cart/ItemTitle";
import st from "./popupItem.module.scss";

export interface PopupItemProps {
  priceItem?: {
    basePrice: number | null;
    discountAmount: number | null;
    id: string;
    item: {
      imgUrl: string | undefined;
      price: number | null;
      subscriptionMetadata: {
        isSubscribable: boolean | undefined;
        subscriptionPrice: number | undefined;
      };
      title: string;
    };
    quantity: number;
    subscriptionIntervalInDays: number | false;
  };
}

/**
 * matches libs/ui/src/lib/components/cart/CartItem.tsx but
 * uses priceItem instead of cartItem and has no actions
 */
export const PopupItem: FC<PopupItemProps> = ({ priceItem }) => {
  const { item, quantity, subscriptionIntervalInDays } = priceItem || {};

  const { title, price, imgUrl, subscriptionMetadata } = item || {};

  const calculatedPerUnitPrice = useMemo(() => {
    if (subscriptionIntervalInDays)
      return subscriptionMetadata?.subscriptionPrice || 0;
    return price || 0;
  }, [subscriptionIntervalInDays, subscriptionMetadata, price]);

  const calculatedStrikethroughUnitPrice = useMemo(() => {
    if (subscriptionIntervalInDays && (price || 0) > calculatedPerUnitPrice) {
      return price;
    }
  }, [calculatedPerUnitPrice, subscriptionIntervalInDays, price]);

  if (!priceItem || !item) return null;

  return (
    <div className={st.popupItem} data-testid="popup-item">
      <ItemImage
        itemImgSrc={imgUrl || ""}
        productTitle={title || "popup item"}
        isLink={false}
        useProductCdn={false}
        small
      />

      <div className={st.details}>
        <ItemTitle productTitle={title || "popup item"} small />

        <ItemPrice
          strikethroughUnitPrice={calculatedStrikethroughUnitPrice}
          perUnitPrice={calculatedPerUnitPrice}
          small
        />

        {subscriptionIntervalInDays && (
          <ItemSubDetails
            subscribed={!!subscriptionIntervalInDays}
            interval={subscriptionIntervalInDays}
          />
        )}

        <div className={st.qty}>Qty: {quantity}</div>
      </div>
    </div>
  );
};
