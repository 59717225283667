import React from "react";

export const CalendarIconSvg = () => (
  <svg
    width="26"
    height="30"
    viewBox="0 0 26 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8999 4.19998H20.1999V0.599976H17.4999V4.19998H8.4999V0.599976H5.7999V4.19998H3.0999C1.6059 4.19998 0.399902 5.42398 0.399902 6.89998V26.7C0.399902 28.176 1.6059 29.4 3.0999 29.4H22.8999C24.3939 29.4 25.5999 28.176 25.5999 26.7V6.89998C25.5999 5.42398 24.3939 4.19998 22.8999 4.19998ZM22.8999 26.7H3.0999V13.2H22.8999V26.7ZM22.8999 10.5H3.0999V6.89998H22.8999V10.5ZM8.4999 18.6H5.7999V15.9H8.4999V18.6ZM14.3499 18.6H11.6499V15.9H14.3499V18.6ZM20.1999 18.6H17.4999V15.9H20.1999V18.6ZM8.4999 24H5.7999V21.3H8.4999V24ZM14.3499 24H11.6499V21.3H14.3499V24ZM20.1999 24H17.4999V21.3H20.1999V24Z"
      fill="black"
    />
  </svg>
);
