import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { BodyClassContextType } from "types/bodyClassContext";

export function useBodyClassProvider(): {
  bodyContextValue: BodyClassContextType;
} {
  const router = useRouter();
  const [bodyClass, setBodyClass] = useState<string[]>([]);

  useEffect(() => {
    const path = router?.pathname;
    switch (true) {
      case path === "/":
        return setBodyClass((prev) => [
          "home",
          "page-template-default",
          "has-main-navigation",
          "page",
          "singular",
          "whitebg",
        ]);
      case path === "/focus":
        return setBodyClass((prev) => [
          "home",
          "page-template-default",
          "has-main-navigation",
          "whitebg",
        ]);
      case path === "/events":
      case path === "/athletes":
        return setBodyClass((prev) => [
          "focus",
          "page-template-default",
          "has-main-navigation",
          "whitebg",
        ]);
      case /athletes\/+/.test(path):
        return setBodyClass((prev) => [
          "focus",
          "athletes-template-default",
          "has-main-navigation",
          "whitebg",
        ]);
      default:
        return setBodyClass((prev) => [
          "whitebg",
          "post-template-default",
          "has-main-navigation",
        ]);
    }
  }, [router?.pathname]);

  const addClass = (className: string) => {
    if (className) {
      setBodyClass((prev) => [...prev, className]);
    }
  };

  const removeClass = (classNameToRemove: string) => {
    if (classNameToRemove) {
      setBodyClass((prev) => prev.filter((item) => item !== classNameToRemove));
    }
  };

  const bodyContextValue: BodyClassContextType = {
    bodyClass,
    addClass,
    removeClass,
  };

  return { bodyContextValue };
}
